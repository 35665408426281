<div class="header-app p-3 d-flex justify-content-between">
    <div>
        <i class="fa fa-bars pr-2" (click)="abrirMenuLateral()"></i>
        <span class="fs-14">{{nomRepatidor | titlecase}}</span>
    </div>
    <div class="d-flex align-items-center">
        <span class="fs-12 pr-2">{{ estadoOnline }}</span>
        <mat-slide-toggle *ngIf="!isRepartidorPropio" (change) = "repartidorOnLine($event);"  [checked] = "isTogleActive"></mat-slide-toggle>
    </div>
</div>
